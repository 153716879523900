import React, { useState } from 'react'
import { Collapse, Container, Row } from 'react-bootstrap'
import Arrow from './Arrow'
import moment from 'moment'

export default function Experience () {
  const [isOpenExperienceBox, setOpenExperienceBox] = useState(false)
  const [isOpenOuiSpoon, setOpenOuiSpoon] = useState(false)
  return (
    <Container fluid style={{
      borderColor: '#33FFBDBF',
      borderRadius: 10,
      borderWidth: 3,
      borderStyle: 'solid',
      padding: 25,
      paddingTop: 10,
      marginTop: 20
    }}>
      <Row
        onClick={() => setOpenExperienceBox(!isOpenExperienceBox)}
        aria-controls="experience-box"
        aria-expanded={isOpenExperienceBox}
        style={{cursor: 'pointer', alignItems: 'center'}}>
        <Arrow open={isOpenExperienceBox}/>
        <h1>Expérience</h1>
      </Row>
      <Container fluid>
        <Row
          onClick={() => setOpenOuiSpoon(!isOpenOuiSpoon)}
          aria-controls="ouispoon-box"
          aria-expanded={isOpenOuiSpoon}
          style={{cursor: 'pointer', alignItems: 'center'}}>
          <div className={'flex-sm-row'}><Arrow open={isOpenOuiSpoon}/></div>
          <div className={'flex-sm-row pr-2'}>2018 - maintenant:</div>
          <div className={'flex-sm-row'}>Développeur Web - OuiSpoon</div>
        </Row>
        <Collapse in={isOpenOuiSpoon}>
          <Container id={'ouispoon-box'}>
            <small>
              <Row>
                <div className={'flex-sm-row pr-2'}>03/2018 -> 08/2018</div>
                <div className={'flex-sm-row'}>
                  <div className={'flex-sm-row'}>Backend développeur</div>
                  <div className={'flex-sm-row pl-3 text-muted'}>
                    <u className={'pr-2'}>Technos utilisées:</u> Node.js, express, Git, CircleCI, PostgreSql
                  </div>
                  <div className={'flex-sm-row pl-3 text-muted'}>
                    <u className={'pr-2'}>Responsabilités:</u>
                    Gestion et création intégrale du back d'une nouvelle application
                  </div>
                </div>
              </Row>
              <Row>
                <div className={'flex-sm-row pr-2'}>08/2018 -> 07/2019</div>
                <div className={'flex-sm-row'}>
                  <div className={'flex-sm-row'}>Full stack web développeur</div>
                  <div className={'flex-sm-row pl-3 text-muted'}>
                    <u className={'pr-2'}>Technos utilisées:</u> React.js, Node.js, express, Git, CircleCI, PostgreSql
                  </div>
                  <div className={'flex-sm-row pl-3 text-muted'}>
                    <u className={'pr-2'}>Responsabilités:</u> Relecture des Pull Request, validation des PR et merge
                    des PR.
                  </div>
                </div>
              </Row>
              <Row>
                <div className={'flex-sm-row pr-2'}>07/2019 -> {moment().format('MM/YYYY')}</div>
                <div className={'flex-sm-row'}>
                  <div className={'flex-sm-row'}>Full stack mobile développeur</div>
                  <div className={'flex-sm-row pl-3 text-muted'}>
                    <u className={'pr-2'}>Technos utilisées:</u> TypeScript, React native, expo, Node.js, express, Git,
                    PostgreSql
                  </div>
                  <div className={'flex-sm-row pl-3 text-muted'}>
                    <u className={'pr-2'}>Responsabilités:</u>
                    Relecture des Pull Request, validation des PR et merge des PR.<br/>
                    <span className={'pl-3'}>Référent technique sur l'intégration d'outils tierces à l'application.<br/></span>
                    <span className={'pl-3'}>Gestion des mises en productions de l'application sur les stores (Front) et sur le serveur
                        (Back).</span>
                  </div>
                </div>
              </Row>
            </small>
          </Container>
        </Collapse>
        <Collapse in={isOpenExperienceBox} style={{paddingLeft: 0}}>
          <Container fluid id="experience-box">
            <Row>
              <div className={'flex-sm-row pr-2'}>01/2017 - 07/2017:</div>
              <div className={'flex-sm-row'}>
                <div className={'flex-sm-row'}>Téléconseiller – Senior Media</div>
                <small>
                  <div className={'flex-sm-row flex-wrap ml-3 text-muted'}>
                    <u className={'pr-2'}>
                      Objectif :
                    </u>
                    Qualifier les demandes des internautes pour les transmettre aux clients.<br/>
                   <span className={'pl-3'}>Ainsi qu'une aide à l’amélioration des procédures et gestion des problèmes clients de premier
                    niveau.</span>
                  </div>
                </small>
              </div>
            </Row>
            <Row>
              <div className={'flex-sm-row pr-2'}>06/2016 - 12/2016:</div>
              <div className={'flex-sm-row'}>
                <div className={'flex-sm-row'}>Ecole Nationale des Sous-Officiers d’Active (ENSOA) – Armée de Terre
                </div>
                <small>
                  <div className={'flex-sm-row flex-wrap ml-3 text-muted'}>
                    <u className={'pr-2'}>
                      Objectif :
                    </u>
                    Devenir un cadre de l’Armée de Terre pouvant diriger, organiser et former un groupe.
                  </div>
                </small>
              </div>
            </Row>
          </Container>
        </Collapse>
      </Container>
    </Container>
  )
}
