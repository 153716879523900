import React, { useState } from 'react'
import { Col, Collapse, Container, Row } from 'react-bootstrap'
import Arrow from './Arrow'

export default function Training () {
  const [isOpenTrainingBox, setOpenTrainingBox] = useState(false)
  const [isOpenEpitech, setOpenEpitech] = useState(false)
  return (
    <Container fluid style={{
      borderColor: '#FF3375BF',
      borderWidth: 3,
      borderStyle: 'solid',
      borderRadius: 10,
      padding: 25,
      paddingTop: 10,
      marginTop: 20
    }}>
      <Row onClick={() => setOpenTrainingBox(!isOpenTrainingBox)} aria-controls="training-box"
           aria-expanded={isOpenTrainingBox} style={{cursor: 'pointer', alignItems: 'center'}}>
        <Arrow open={isOpenTrainingBox}/>
        <h1>Formation</h1>
      </Row>
      <Container fluid>
        <Row
          onClick={() => setOpenEpitech(!isOpenEpitech)}
          aria-controls="epitech-box"
          aria-expanded={isOpenEpitech}
          style={{cursor: 'pointer', alignItems: 'center'}}>
          <div className={'flex-sm-row'}><Arrow open={isOpenEpitech}/></div>
          <div className={'flex-sm-row pr-2'}>2017-2018:</div>
          <div className={'flex-sm-row'}>Coding-Academy by EPITECH</div>
        </Row>
        <Collapse in={isOpenEpitech}>
          <Container id={'epitech-box'}>
            <small className={'text-muted'}>
              <Row>
                <Col sm={3}><u>Langages appris:</u></Col>
                <Col>C, PHP, JS, SQL</Col>
              </Row>
              <Row>
                <Col sm={3}><u>Frameworks vus:</u></Col>
                <Col>Laravel, Symfony, angular</Col>
              </Row>
              <Row>
                <Col sm={3}><u>Environnements de travail:</u></Col>
                <Col>Linux, Git, Atom</Col>
              </Row>
            </small>
          </Container>
        </Collapse>
        <Collapse in={isOpenTrainingBox} style={{paddingLeft: 0}}>
          <Container fluid id="training-box">
            <Row>
              <div className={'flex-sm-row pr-2'}>2016:</div>
              <div className={'flex-sm-row'}>PSC1 (diplôme de Prévention et Secours Civiques de niveau 1)</div>
            </Row>
            <Row>
              <div className={'flex-sm-row pr-2'}>2015-2016:</div>
              <div className={'flex-sm-row'}>
                <div className={'flex-sm-row'}>Master de Chimie, 1ère année – Parcours chimie analytique</div>
                <div className={'flex-sm-row pl-2 text-muted'}>Universités Paris Sud et Paris Saclay – Faculté des sciences
                  d’Orsay
                </div>
              </div>
            </Row>
            <Row>
              <div className={'flex-sm-row pr-2'}>2011-2015:</div>
              <div className={'flex-sm-row'}>
                <div className={'flex-sm-row'}>Licence de chimie</div>
                <div className={'flex-sm-row pl-2 text-muted'}>
                  Universités Paris Sud et Paris Saclay – Faculté des sciences d’Orsay
                </div>
              </div>
            </Row>
            <Row>
              <div className={'flex-sm-row pr-2'}>2011:</div>
              <div className={'flex-sm-row'}>
                Bac S
              </div>
            </Row>
          </Container>
        </Collapse>
      </Container>
    </Container>
  )
}
