import React, { useState } from 'react'
import { Collapse, Container, Row } from 'react-bootstrap'
import Arrow from './Arrow'

export default function Interest () {
  const [isOpen, setOpen]= useState(false)
  return (
    <Container fluid style={{
      borderColor: '#33DDFFBF',
      borderRadius: 10,
      borderWidth: 3,
      borderStyle: 'solid',
      padding: 25,
      paddingTop: 10,
      marginTop: 20
    }}>
      <Row
        onClick={() => setOpen(!isOpen)}
        aria-controls="interest-box"
        aria-expanded={isOpen}
        style={{cursor: 'pointer', alignItems: 'center'}}>
        <Arrow open={isOpen}/>
        <h1>Intérêts</h1>
      </Row>
      <Collapse in={isOpen}>
        <Container id={'interest-box'}>
          <Row>Jeux de Rôle</Row>
          <Row>Jeux de société</Row>
          <Row>Mythologie</Row>
          <Row>Informatique</Row>
          <Row>Nouvelles technologies</Row>
        </Container>
      </Collapse>
    </Container>
  )
}
