import React from 'react'
import Header from './Header'
import { Container } from 'react-bootstrap'
import Training from './Training'
import Experience from './Experience'
import Interest from './Interest'
import Skill from './Skill'


function App () {
  return (
    <Container className={'pt-4'}>
      <Header/>
      <Skill/>
      <Experience/>
      <Training/>
      <Interest/>
    </Container>
  )
}

export default App
