import React, { useState } from 'react'
import { Col, Collapse, Container, Row } from 'react-bootstrap'
import Arrow from './Arrow'

export default function Skill () {
  const [isOpen, setOpen] = useState(false)

  return (
    <Container fluid style={{
      borderColor: '#BB33FFBF',
      borderRadius: 10,
      borderWidth: 3,
      borderStyle: 'solid',
      padding: 25,
      paddingTop: 10,
      marginTop: 20
    }}>
      <Row fluid
           onClick={() => setOpen(!isOpen)}
           aria-controls="interest-box"
           aria-expanded={isOpen}
           style={{cursor: 'pointer', alignItems: 'center'}}>
        <Arrow open={isOpen}/><h1>Compétences</h1>
      </Row>
      <Container fluid>
        <Row><h5>Principales:</h5></Row>
        <Row>
          <Col xs={12} md={4} className={'pt-1'}><u><strong>Node.js:</strong></u> 2 ans d'expérience</Col>
          <Col xs={12} md={4} className={'pt-1'}><u><strong>React.js:</strong></u> + de 1 an d'expérience</Col>
          <Col xs={12} md={4} className={'pt-1'}><u><strong>React Native:</strong></u> 1 an d'expérience</Col>
        </Row>
        <Collapse in={isOpen} className={'pl-0 pt-3'}>
          <Container fluid>
            <Row><h5>Secondaires:</h5></Row>
            <Row>
              <Col xs={12} sm={6} md={4} className={'pt-1'}><u><strong>Git:</strong></u> + de 2 ans d'expérience</Col>
              <Col xs={12} sm={6} md={4} className={'pt-1'}><u><strong>SQL:</strong></u> + de 2 ans d'expérience</Col>
              <Col xs={12} sm={6} md={4} className={'pt-1'}><u><strong>TypeScript:</strong></u> 1 an d'expérience</Col>
              <Col xs={12} sm={6} md={4} className={'pt-1'}><u><strong>CircleCI:</strong></u> 1 an d'expérience</Col>
              <Col xs={12} sm={6} md={4} className={'pt-1'}><u><strong>C++:</strong></u> base</Col>
              <Col xs={12} sm={6} md={4} className={'pt-1'}><u><strong>C:</strong></u> base</Col>
              <Col xs={12} sm={6} md={4} className={'pt-1'}><u><strong>PHP (avec Symfony & Laravel):</strong></u> base</Col>
            </Row>
            <Row className={'pt-3'}><h5>Langue:</h5></Row>
            <Row>
              <Col xs={12} sm={6} md={4} className={'pt-1'}><u><strong>Anglais lu:</strong></u> courant</Col>
              <Col xs={12} sm={6} md={5} className={'pt-1'}><u><strong>Anglais parlé:</strong></u> faible</Col>
            </Row>
            <Row className={'pt-3'}><h5>Environnement de travail:</h5></Row>
            <Row style={{alignItems: 'center'}}>
              <Col xs={12} sm={6} md={4} className={'pl-3 pt-1'}><u><strong>Linux:</strong></u> + de 2 ans d'expérience</Col>
              <Col xs={12} sm={6} md={4} className={'pl-3 pt-1'}><u><strong>WebStorm:</strong></u> 2 ans d'expérience</Col>
            </Row>
          </Container>
        </Collapse>
      </Container>
    </Container>
  )
}
