import React from 'react'
import moment from 'moment'
import { Button, Container, Image, Row } from 'react-bootstrap'

function sendEmail () {
  window.location.assign("mailto:allan@brongniart.site");
}

export default function Header () {
  return (
    <Container fluid>
      <Row fluid className={'justify-content-center'}><h3><u>Développeur Web</u></h3></Row>
      <Row fluid className={'justify-content-between align-items-center'}>
        <div className={'flex-sm-column'}>
          <div>Allan Brongniart</div>
          <small>
            <div>91400 Orsay</div>
            <div>Age: {moment().diff(moment('1993-12-05'), 'y')} ans</div>
            <div>Permis B</div>
            <div><Button onClick={sendEmail} variant={'outline-dark'} size={'sm'}>Contactez-moi par email</Button></div>
          </small>
        </div>
        <div>
          <Image fluid style={{height: 100}} src={'picture.png'} rounded/>
        </div>
      </Row>
    </Container>
  )
}
