import React from 'react'
import { ReactComponent as ArrowIcon } from './arrow.svg'

export default function Arrow (props) {
  return (<ArrowIcon style={{
    fill: 'dark',
    height: 20,
    width: 20,
    marginRight: 5,
    ...props.style,
    transform: `rotate(${props.open ? '-' : ''}90deg)`
  }}/>)
}
